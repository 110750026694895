import React from 'react'
import Layout from '../components/layout/layout'
import Header from '../components/header/header'
import Nav from '../components/nav/nav'
import Footer from '../components/footer/footer'
import coverImage from '../assets/images/lilianafolta-cover.jpg'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main>
      <img src={coverImage} alt="Liliana Folta in her studio"/>
    </main>
    <Footer />
  </Layout>
)
